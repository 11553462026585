<template>
	<Material isTeacher="false"/>
</template>
  
<script>
import Material from '@/components/Material'
export default {
    components:{ 
       Material
	}
  
  
  }
</script>
  
  <style>
  
  </style>