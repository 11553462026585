<template>
  <Material isTeacher="true"/>
</template>

<script>
import Material from '@/components/Material'
export default {
    components:{
        Material
    }


}
</script>

<style>

</style>