<template>
  <Discussion/>
</template>

<script>
import Discussion from '@/components/discussion'
export default {
	components:{
		Discussion
	}


}
</script>

<style>

</style>