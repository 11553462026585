<template>
  <div>
    <MyDiscussion/>
  </div>
</template>

<script>
import MyDiscussion from '@/components/MyDiscussion'
export default {
  components:{
    MyDiscussion
  }

}
</script>

<style>

</style>