<template>
	<div>
      <ClassHomePage/>
	</div>
</template>
  
<script>
import ClassHomePage from '@/components/ClassHomePage'
export default {
components:{
      ClassHomePage
}
  
}
</script>
  
<style>
  
</style>